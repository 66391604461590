<template>
  <Loader :globalData="globalData"/>
  <Header :globalData="globalData"/>
  <div class="alert-deck">
    <Alerts :globalError="error" v-for="error in globalData.errors"/>
  </div>
  <div class="main container">
    <router-view :globalData="globalData"/>
  </div>
</template>

<script>
import { reactive} from 'vue'
import Header from './components/Header.vue'
import Alerts from './components/Alerts.vue'
import Loader from "./components/Loader";

export default {
  name: 'App',
  props: ['serverData'],
  components: {
    Loader,Header,Alerts
  },
  data(){
    console.log(this.serverData)
    const globalData = reactive(this.serverData)
    globalData.loader.status = false
    const mainClass = 'blue'
    const body = document.querySelector('body')
    body.classList.add(mainClass)
    return {
      globalData
    };
  }
}
</script>

<style>
.alert-deck{
  position:fixed;
  width:100%;
  max-width:350px;
  z-index:3900;
  top:0;
  right:0;}
</style>
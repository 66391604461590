<template>
  <transition name="alert-fade">
    <div class="alert" :class="globalError.type" v-if="globalError.msg" v-show="alertVisible">
      {{ globalError.msg }}
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Alerts',
  props: ['globalError'],
  computed: {
    alertVisible : function(){
      if(this.globalError.msg!=null){
        setTimeout(() => this.globalError.msg = null, 3500)
        return true;
      }
      return false
    }
  }
}
</script>
<style scoped>
.alert{
  margin:15px;
  padding:.9rem;
  display:flex;
  align-items:center;
  justify-content:space-between;
  border:0;
  border-radius:.23rem;
  opacity:1;
  overflow:hidden;
  min-height:70px;
  background-color: #65d1d4;
  cursor:pointer;
  color:#ffffff;
  animation:createAlert .25s ease-in-out;
}
.alert.success{
  background-color: green;
}
.alert.error{
  background-color: red;
}

.alert-fade-enter-active,
.alert-fade-leave-active {
  animation:createAlert .25s ease-in-out;
}

.alert-fade-enter-from,
.alert-fade-leave-to {
  animation:removeAlert .25s ease-in-out;
}

@keyframes createAlert {
  0% {opacity:0;transform:translateY(-50px);}
  100% {opacity:1;transform:translateY(0);}
}
@keyframes removeAlert {
  0% {opacity:1;transform:translateY(0);}
  100% {opacity:0;transform:translateY(-50px);}
}
</style>
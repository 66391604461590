import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/style.css'
import axios from 'axios'

const app = createApp(App,{serverData: serverData})

axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$myApiKey = process.env.VUE_APP_API_MY_KEY;

app.use(router,axios)
app.mount('#app')

<template>
  <div class="spinner" v-show="loaderVisible">
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: ['globalData'],
  computed: {
    loaderVisible : function(){
      return this.globalData.loader.status
    }
  }
}
</script>
<style scoped>
.spinner{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color:rgba(0, 0, 0, 0.5);
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
  border-top-color: #FF757D;
  animation: spinner .6s linear infinite;
}
@keyframes spinner {
  to {transform: rotate(360deg);}
}
</style>
import { createRouter, createWebHistory } from 'vue-router'

const routeData = serverData.init.routes
const routes = new Array()
for(let i=0;i<routeData.length;i++){
  routes.push(
      {
        path: routeData[i]['path'],
        name: routeData[i]['name'],
        component: () => import('../views/'+routeData[i]['component']+'.vue')
      }
  )
}

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router

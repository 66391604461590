<template>
  <header class="header">
    <nav class="navbar navbar-light">
      <div class="container"> 
        <router-link class="logo" :to="{name : 'Md5'}">
          MikroBi Tools
        </router-link>
<!--        <router-link :to="{name : 'Test'}">-->
<!--          Test-->
<!--        </router-link>-->
      </div>
    </nav>
  </header>
</template>

<script>

export default{
  name:'Header',
  props:['globalData']
}
</script>

<style scoped>
.header{
  position: fixed;
  top: 0;
  width: 100%;
}
header a span{
  vertical-align: middle;
}

</style>